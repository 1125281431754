import React, { useEffect, useRef } from 'react'
import {
  axisBottom,
  axisLeft,
  select,
  format,
  scaleBand,
  ScaleBand,
  scaleLinear,
  ScaleLinear,
} from 'd3'

export interface Data {
  label: string
  value: number
}

interface AxisBottomProps {
  scale: ScaleBand<string>
  transform: string
}

const AxisBottom: React.FC<AxisBottomProps> = ({
  scale,
  transform,
}: AxisBottomProps) => {
  const ref = useRef<SVGGElement>(null)

  useEffect(() => {
    if (ref.current) {
      select(ref.current).call(axisBottom(scale))
    }
  }, [scale])

  return <g ref={ref} transform={transform} />
}

interface AxisLeftProps {
  scale: ScaleLinear<number, number, never>
}

const AxisLeft: React.FC<AxisLeftProps> = ({ scale }: AxisLeftProps) => {
  const ref = useRef<SVGGElement>(null)

  useEffect(() => {
    if (ref.current) {
      const ticks = scale.ticks().filter((tick) => Number.isInteger(tick))
      select(ref.current).call(
        axisLeft(scale).tickValues(ticks).tickFormat(format('d'))
      )
    }
  }, [scale])

  return <g ref={ref} />
}

interface BarsProps {
  data: BarChartProps['data']
  height: number
  scaleX: AxisBottomProps['scale']
  scaleY: AxisLeftProps['scale']
}

const Bars: React.FC<BarsProps> = ({
  data,
  height,
  scaleX,
  scaleY,
}: BarsProps) => {
  return (
    <>
      {data.map(({ value, label }) => (
        <rect
          key={`bar-${label}`}
          x={scaleX(label)}
          y={scaleY(value)}
          width={scaleX.bandwidth()}
          height={height - scaleY(value)}
          fill="teal"
        />
      ))}
    </>
  )
}

export interface BarChartProps {
  data: Data[]
}

const BarChart: React.FC<BarChartProps> = ({ data }: BarChartProps) => {
  const margin = { top: 10, right: 0, bottom: 20, left: 30 }
  const width = 500 - margin.left - margin.right
  const height = 300 - margin.top - margin.bottom

  const scaleX = scaleBand()
    .domain(data.map(({ label }) => label))
    .range([0, width])
    .padding(0.1)
  const scaleY = scaleLinear()
    .domain([0, Math.max(...data.map(({ value }) => value))])
    .range([height, 0])

  return (
    <svg
      width={width + margin.left + margin.right}
      height={height + margin.top + margin.bottom}
    >
      <g transform={`translate(${margin.left}, ${margin.top})`}>
        <AxisBottom scale={scaleX} transform={`translate(0, ${height})`} />
        <AxisLeft scale={scaleY} />
        <Bars data={data} height={height} scaleX={scaleX} scaleY={scaleY} />
      </g>
    </svg>
  )
}

export default BarChart
