import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import groupBy from 'lodash.groupby'
import mapValues from 'lodash.mapvalues'
import values from 'lodash.values'
import moment from 'moment'

import Layout from '../components/layout'
import BarChart from '../components/graph'

const Test: React.FC<any> = () => {
  const data = useStaticQuery(graphql`
    query Publications {
      allStrapiPublication {
        nodes {
          date
        }
      }
    }
  `).allStrapiPublication.nodes
  const paperPerYear = values(
    mapValues(
      groupBy(data, ({ date }) => moment(date).year()),
      (papers, key) => ({ label: key, value: papers.length })
    )
  )
  return (
    <Layout tocEnabled={false}>
      <h1>Test</h1>
      <BarChart data={paperPerYear} />
    </Layout>
  )
}

export default Test
